import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { Location } from '@angular/common';

// Material
import { MatSnackBar } from '@angular/material/snack-bar';

// RxJs
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

// 3rd
import PerfectScrollbar from 'perfect-scrollbar';

// App
// Root Singleton Services
import { ConnectionResolver, LayoutService, ThemeService, UserPermissionsService } from 'app/core/services';

// NgRx
import { select, Store } from '@ngrx/store';
import * as fromRoot from 'app/core/store';
import * as fromAuth from 'app/auth/store';
import { FeatureFlagService } from '../../../../views/settings/feature-flag/services/feature-flag.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public isModuleLoading: Boolean = false;
  public layoutConf: any = {};
  public isFullHeight: boolean = false;
  hasValidRoute = false;
  // public environment: BotEnvironments;
  public environment$: Observable<string>;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;
  private websocketSub: Subscription;
  private isBotCreatedSub: Subscription;

  private isMobile;
  // private sidebarPS: PerfectScrollbar;
  private bodyPS: PerfectScrollbar;
  private headerFixedBodyPS: PerfectScrollbar;
  // public environment: BotEnvironments;
  isBotCreated$: Observable<boolean>;
  private featureFlagSubscription: Subscription;

  constructor(
    private location: Location,
    private router: Router,
    private layout: LayoutService,
    private connectionResolver: ConnectionResolver,
    private store: Store<fromRoot.AppState>,
    private featureFlagService: FeatureFlagService,
    private userPermissionsService: UserPermissionsService,
    private snackbar: MatSnackBar
  ) {
    // Close sidenav after route change in mobile
    this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: NavigationEnd) => {
      if (this.isSm()) {
        this.closeSidebar();
      }
    });

    this.layout.isFullHeight = false;
  }

  ngOnInit() {
    this.environment$ = this.store.pipe(select(fromAuth.getEnvironment));
    this.isBotCreated$ = this.store.pipe(select(fromAuth.isBotCreated));
    this.isBotCreatedSub = this.store.pipe(select(fromAuth.isBotCreated)).subscribe(isExists => {
      if (isExists === false && this.router.url !== '/virtual-agents/create') {
        this.router.navigateByUrl('/virtual-agents/create');
      }
    });

    this.websocketSub = this.store
      .pipe(select(fromAuth.getToken))
      .pipe(
        filter(token => token != null),
        first()
      )
      .subscribe(token => {
        this.connectionResolver.startconnection(token);
      });

    this.router.events.subscribe(event => {
      if (this.location.path() != '') {
        this.hasValidRoute = !this.location.path().includes('/chat');
      }
      this.init();

      if (event instanceof NavigationError) {
        if (event?.error?.statusCode === 403) {
          this.snackbar.open("You don't have access to this page", 'Dismiss');
        }
      }
    });

    this.init();
    this.getFeatureFlags();
    this.userPermissionsService.setPermissions(JSON.parse(sessionStorage.getItem('userObject'))?.permissions);
  }

  init() {
    this.layoutConf = this.layout.layoutConf;
    this.isMobile = this.isSm();
    this.layout.publishLayoutChange({
      isMobile: this.isMobile,
      sidebarStyle: this.isMobile ? 'closed' : 'compact'
    });
    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
    this.isFullHeight = this.layout.isFullHeight;
  }

  ngAfterViewInit() {
    this.layoutConfSub = this.layout.layoutConf$.subscribe(change => {
      this.initBodyPS(change);
    });
    this.sidebarAutoPadding();
  }

  initBodyPS(layoutConf: any = {}) {
    if (layoutConf.navigationPos === 'side' && layoutConf.topbarFixed) {
      if (this.bodyPS) {
        this.bodyPS.destroy();
      }
      if (this.headerFixedBodyPS) {
        this.headerFixedBodyPS.destroy();
      }
      this.headerFixedBodyPS = new PerfectScrollbar('.rightside-content-hold', {
        suppressScrollX: true
      });
    } else {
      if (this.bodyPS) {
        this.bodyPS.destroy();
      }
      if (this.headerFixedBodyPS) {
        this.headerFixedBodyPS.destroy();
      }
      this.bodyPS = new PerfectScrollbar('.main-content-wrap', {
        suppressScrollX: true
      });
    }
  }

  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  isSm() {
    return window.matchMedia(`(max-width: 768px)`).matches;
  }

  onMainContentScroll(event): void {
    this.sidebarAutoPadding();
  }

  sidebarAutoPadding(): void {
    var sidebarPaddingElement = document.getElementById('sidebar-auto-padding');
    if (sidebarPaddingElement) {
      var scrollTop = document.getElementById('main-content-wrap').scrollTop;
      var height = document.getElementById('main-top-bar').offsetHeight;
      if (scrollTop < height) {
        var paddingTop = height - scrollTop;
        sidebarPaddingElement.style.paddingTop = paddingTop + 'px';
      } else {
        sidebarPaddingElement.style.paddingTop = '0';
      }
    }
  }

  getFeatureFlags(): void {
    this.featureFlagSubscription = this.featureFlagService.getFeatureFlags().subscribe(res => {
      sessionStorage.setItem('featureFlags', JSON.stringify(res));
      this.featureFlagService.changeFeatureFlags(res);
    });
  }

  ngOnDestroy(): void {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
    if (this.websocketSub) {
      this.connectionResolver.closeHub();
      this.websocketSub.unsubscribe();
    }
    if (this.featureFlagSubscription) {
      this.featureFlagSubscription.unsubscribe();
    }
    // if (this.environmentSub) {
    //   this.routerEventSub.unsubscribe();
    // }
  }
}
