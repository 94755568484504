import { TwoFactorResponse } from 'app/domain/dtos/configuration/TwoFactorResponse';
import { TwoFactorAuthenticationRequest } from 'app/domain/dtos/configuration/TwoFactorAuthenticationRequest';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

// RxJs
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

// Material
import { MatSnackBar } from '@angular/material/snack-bar';

// NgRx
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as authActions from './auth.actions';
import { resetPasswordSuccess } from './auth.actions';
import * as fromRoot from 'app/core/store';
import * as fromAuth from '.';

// MSAL
import { MsalService } from '@azure/msal-angular';
import { AuthError } from 'msal';

// APP
import { UserAppSettings } from 'app/domain/userAppSettings';
import { BotEnvironments } from 'app/domain/constants/BotEnvironments';
import { AuthResponse } from 'app/domain/dtos/configuration/AuthResponse';
import { RedirectResponse } from 'app/domain/dtos/configuration/RedirectResponse';
import { LoginResponseObj } from 'app/domain/loginResponseObj';

// Root singleton Services
import { ErrorService } from 'app/core/services/error.service';
import { SessionsService } from 'app/auth/sessions/sessions.service';
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { ResetPasswordRequest } from 'app/domain/dtos/configuration/ResetPasswordRequest';
import { ConfirmTwoFARequest } from 'app/domain/dtos/configuration/ConfirmTwoFARequest';
import { RouteService } from '../sessions/route.service';

export interface ResetModel {
  isSuccessfull: boolean;
  errors: string[];
}

@Injectable()
export class AuthEffects {
  userObjectKey = 'userObject';
  userAppSettingsKey = 'appSettings';
  chooseTwoFaRoute = '/sessions/choose-twofa';
  confirmTwoFaRoute = '/sessions/confirm-twofa';
  // Loading from session storage if not go to login
  loadFromSessionStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadFromSessionStorage),
      map(action => {
        const userObject = JSON.parse(this.sessionStorage.get(this.userObjectKey));
        let appSettings = JSON.parse(this.sessionStorage.get(this.userAppSettingsKey));

        // if environment came from URL
        const urlParts = window.location.pathname.split('/');
        const env = `${urlParts[1].charAt(0).toUpperCase()}${urlParts[1].slice(1).toLowerCase()}`;

        // http://localhost:4200/test/conversations?search=13&hasRetries=1&va=1
        if (urlParts.length > 1 && [BotEnvironments.prod, BotEnvironments.test].includes(env)) {
          appSettings = { ...appSettings, environment: env };
          this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
        }

        return authActions.loadFromSessionStorageSuccess({
          payload: {
            userObject,
            appSettings
          }
        });
      })
    )
  );
  // Loading from local storage if not go to login
  loadFromSessionStorageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadFromSessionStorageSuccess),
      withLatestFrom(this.store.pipe(select(fromAuth.getExpires))),
      map(([action, expires]) => {
        // If We have a user object and token is not expired
        if (expires && expires > Date.now().valueOf() / 1000) {
          // If logged in with Azure get profile
          if (!!this.msalService.getAccount()) {
            return authActions.refreshAzureToken();
          } else {
            return authActions.refreshToken();
          }
        }
        // If expired token logout
        else if ((expires && expires <= Date.now().valueOf() / 1000) || !!this.msalService.getAccount()) {
          return authActions.refreshAzureToken();
        }

        return authActions.navigateToRedirectURL();
      })
    )
  );
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      withLatestFrom(this.store.pipe(select(fromAuth.getEnvironment)), this.store.pipe(select(fromAuth.getBotId))),
      mergeMap(([action, env, botId]) => {
        return this.sessionService.login(action.payload.username, action.payload.password).pipe(
          map((res: AuthResponse | RedirectResponse) => {
            if ((res as RedirectResponse)?.shouldRedirectToTwoFactor) {
              return authActions.navigateToTwoFAURL({
                payload: {
                  email: (res as RedirectResponse).email,
                  navigationUrl: this.chooseTwoFaRoute,
                  phoneNumber: (res as RedirectResponse).phoneNumber
                }
              });
            }

            const appSettings: UserAppSettings = new UserAppSettings();
            const userObject = new LoginResponseObj();
            userObject.parseFromApi(res);

            // if Bots table is empty - no bot created yet
            if ((res as AuthResponse).isBotCreated === false && (res as AuthResponse).userBots.length === 0) {
              appSettings.environment = BotEnvironments.test;
              appSettings.botId = 1;
              appSettings.urlLoaded = this.sessionStorage.get('appSettings')
                ? JSON.parse(this.sessionStorage.get('appSettings'))?.urlLoaded.toLowerCase()
                : `${BotEnvironments.test}/reports/view`;
              this.sessionStorage.set(this.userObjectKey, JSON.stringify(userObject));
              this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
              return authActions.loginSuccess({ payload: { userObject, appSettings } });
            }

            // if there is a bot created in db - but user has that tries to log in does not have a bot asigned
            if ((res as AuthResponse).userBots.length === 0 && (res as AuthResponse).isBotCreated) {
              return authActions.loginFail({ errorMessage: 'User not linked to a bot' });
            }

            this.sessionStorage.set(this.userObjectKey, JSON.stringify(userObject));
            appSettings.environment = env ? env : BotEnvironments.prod;
            appSettings.urlLoaded = this.sessionStorage.get('appSettings')
              ? JSON.parse(this.sessionStorage.get('appSettings'))?.urlLoaded.toLowerCase()
              : `${BotEnvironments.test}/reports/view`;
            appSettings.botId = botId ? botId : userObject.userBots[0].id;
            this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
            return authActions.loginSuccess({ payload: { userObject, appSettings } });
          }),
          catchError(err => {
            const { message } = err;
            this.snackbar.open(message || message?.message, 'Close', { duration: 3000 });
            return of(authActions.loginFail({ errorMessage: message?.message }));
          })
        );
      })
    )
  );

  confirmTwoFALogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.confirmTwoFa),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getTwoFAType)),
        this.store.pipe(select(fromAuth.getEmail)),
        this.store.pipe(select(fromAuth.getTwoFAToken)),
        this.store.pipe(select(fromAuth.getEnvironment)),
        this.store.pipe(select(fromAuth.getBotId))
      ),
      mergeMap(([action, type, email, token, env, botId]) => {
        const request: ConfirmTwoFARequest = new ConfirmTwoFARequest();
        request.email = email;
        request.type = type;
        request.token = token;
        return this.sessionService.confirmTwoFA(request).pipe(
          map((res: AuthResponse) => {
            if ((res as AuthResponse).userBots.length === 0) {
              return authActions.loginFail({ errorMessage: 'User not linked to a bot' });
            }
            const userObject = new LoginResponseObj();
            userObject.parseFromApi(res);
            this.sessionStorage.set(this.userObjectKey, JSON.stringify(userObject));

            const appSettings: UserAppSettings = new UserAppSettings();
            appSettings.environment = env ? env : BotEnvironments.prod;
            appSettings.botId = botId ? botId : userObject.userBots[0].id;
            appSettings.urlLoaded = this.sessionStorage.get('appSettings')
              ? JSON.parse(this.sessionStorage.get('appSettings'))?.urlLoaded.toLowerCase()
              : `${BotEnvironments.test}/reports/view`;
            this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));

            return authActions.loginSuccess({ payload: { userObject, appSettings } });
          }),
          catchError(err => {
            this.snackbar.open('Authentication Failed. Incorrect Token', 'Close');
            const error = this.errorService.format(err);
            return of(authActions.loginFail({ errorMessage: error.message }));
          })
        );
      })
    )
  );

  generateTwoFAToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.generateTwoFAToken),
      withLatestFrom(this.store.pipe(select(fromAuth.getTwoFAType)), this.store.pipe(select(fromAuth.getEmail))),
      mergeMap(([action, type, email]) => {
        const request = new TwoFactorAuthenticationRequest();
        request.email = email;
        request.type = type;

        return this.sessionService.twoFAMethod(request).pipe(
          map((res: TwoFactorResponse) => {
            if (res) {
              return authActions.navigateToTwoFAURL({ payload: { email: email, navigationUrl: this.confirmTwoFaRoute, phoneNumber: '' } });
            }
          })
        );
      })
    )
  );

  // This will navigate to Azure Authentication
  loginMSAL$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginMSAL),
        tap(res => {
          this.msalService.loginRedirect();
        })
      ),
    { dispatch: false }
  );

  // Login with ebo backend with Azure token
  loginWithAzureToken$ = createEffect((): any => {
    return this.actions$.pipe(
      ofType(authActions.loginWithAzureToken),
      withLatestFrom(this.store.pipe(select(fromAuth.getEnvironment)), this.store.pipe(select(fromAuth.getBotId))),
      switchMap(([action, env, botId]) => {
        return this.sessionService.loginWithAzureToken().pipe(
          map((res: AuthResponse) => {
            if (res) {
              if (res.userBots.length === 0) {
                return authActions.loginFail({ errorMessage: 'User not linked to a Virtual Agent' });
              } else if (res.permissions.length === 0) {
                return authActions.loginFail({ errorMessage: 'User has no access permissions' });
              } else {
                const loginObject = new LoginResponseObj();
                loginObject.parseFromApi(res);
                const appSettings: UserAppSettings = new UserAppSettings();
                appSettings.environment = env ? env : BotEnvironments.prod;
                appSettings.botId = botId ? botId : loginObject.userBots[0].id;
                appSettings.urlLoaded = this.sessionStorage.get('appSettings')
                  ? this.sessionStorage.get('redirectUrl')?.toLowerCase()
                  : `${BotEnvironments.test}/reports/view`;
                const userObject = new LoginResponseObj();
                userObject.parseFromApi(res);

                if (res.isBotCreated === false) {
                  appSettings.environment = BotEnvironments.test;
                  appSettings.botId = 1;
                  appSettings.urlLoaded = this.sessionStorage.get('appSettings')
                    ? JSON.parse(this.sessionStorage.get('appSettings'))?.urlLoaded.toLowerCase()
                    : `${BotEnvironments.test}/reports/view`;
                }
                this.sessionStorage.set(this.userObjectKey, JSON.stringify(loginObject));
                this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));

                return authActions.loginSuccess({
                  payload: {
                    userObject: loginObject,
                    appSettings
                  }
                });
              }
            }
          }),
          catchError((error: any): any => {
            window.sessionStorage.clear();
            return of(authActions.loginFail({ errorMessage: error.message || error.message.message }));
          })
        );
      })
    );
  });

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      map(action => {
        return authActions.navigateToRedirectURL();
      })
    )
  );

  navigateToRedirectURL$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.navigateToRedirectURL),
        withLatestFrom(this.store.pipe(select(fromAuth.getEnvironment))),
        map(([action, env]) => {
          const url = window.location.pathname;
          if (JSON.parse(this.sessionStorage.get('loginRedirect')) === true && url !== '/sessions/signin') {
            this.sessionStorage.remove('redirectUrl');
          }
          const isUserUsedSearch =
            JSON.parse(this.sessionStorage.get('appSettings'))?.urlLoaded?.toLowerCase() || `/${env.toLowerCase()}/reports`;
          if (
            isUserUsedSearch &&
            isUserUsedSearch !== '/' &&
            isUserUsedSearch !== null &&
            isUserUsedSearch !== '/sessions/signin' &&
            isUserUsedSearch !== '/sessions/confirm-twofa'
          ) {
            this.routeService.redirectToSavedRoute();
          } else if (isUserUsedSearch && isUserUsedSearch.includes('/sessions/reset-password')) {
            this.routeService.redirectToLogin();
          } else {
            let redirectURL = this.sessionStorage.get('redirectUrl');
            this.sessionStorage.set('loginRedirect', 'true');
            redirectURL =
              redirectURL != '/' && redirectURL != null && redirectURL != '/sessions/signin'
                ? redirectURL
                : `/${env.toLowerCase()}/reports`;
            this.router.navigateByUrl(redirectURL);
          }
        })
      ),
    { dispatch: false }
  );

  navigateToTwoFAURL$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.navigateToTwoFAURL),
        withLatestFrom(
          this.store.pipe(select(fromAuth.getEmail)),
          this.store.pipe(select(fromAuth.getNavigationUrl)),
          this.store.pipe(select(fromAuth.getPhoneNumber))
        ),
        map(([action, email, navigationUrl, phoneNumber]) => {
          this.router.navigateByUrl(navigationUrl, { state: { email: email, phoneNumber: phoneNumber } });
        })
      ),
    { dispatch: false }
  );

  refreshAzureToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshAzureToken),
      mergeMap(action => {
        return from(
          this.msalService.acquireTokenSilent({
            scopes: this.msalService.getScopesForEndpoint(`${environment.apiUrl}/oauth/azure/token`)
          })
        ).pipe(
          map(() => {
            return authActions.loginWithAzureToken();
          }),
          catchError((error: AuthError) => {
            if (error.name.indexOf('InteractionRequiredAuthError') !== -1) {
              return of(authActions.refreshTokenRedirectMSAL());
            }
            return of(authActions.loginFail({ errorMessage: error.message }));
          })
        );
      })
    )
  );
  refreshTokenRedirectMSAL$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.refreshTokenRedirectMSAL),
        map(action => {
          this.msalService.acquireTokenRedirect({
            scopes: this.msalService.getScopesForEndpoint(`${environment.apiUrl}/oauth/azure/token`)
          });
        })
      ),
    { dispatch: false }
  );
  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshToken),
      withLatestFrom(this.store.pipe(select(fromAuth.getEnvironment)), this.store.pipe(select(fromAuth.getBotId))),
      mergeMap(([action, env, botId]) => {
        return this.sessionService.refreshToken().pipe(
          map((res: AuthResponse) => {
            const appSettings: UserAppSettings = new UserAppSettings();
            const userObject = new LoginResponseObj();

            if (res.userBots.length === 0 && res.isBotCreated) {
              return authActions.loginFail({ errorMessage: 'User not linked to a Virtual Agent' });
            }

            // if Bots table is empty - no bot created yet
            if (res.isBotCreated === false) {
              appSettings.environment = BotEnvironments.test;
              appSettings.botId = 1;
              appSettings.urlLoaded =
                JSON.parse(this.sessionStorage.get('appSettings')).urlLoaded.toLowerCase() || `${BotEnvironments.test}/reports/view`;
            }

            //   this.storageService.userObject = loginResponse;
            userObject.parseFromApi(res);
            appSettings.environment = env ? env : BotEnvironments.prod;
            appSettings.urlLoaded =
              JSON.parse(this.sessionStorage.get('appSettings')).urlLoaded.toLowerCase() || `${BotEnvironments.test}/reports/view`;
            appSettings.botId = botId ? botId : userObject.userBots[0].id;
            this.sessionStorage.set(this.userObjectKey, JSON.stringify(userObject));
            this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
            return authActions.loginSuccess({ payload: { userObject, appSettings } });
          }),
          catchError(err => {
            // if (err === 'User not linked to a virtual agent')
            this.snackbar.open('Unable to connect with the server.', 'Close', {
              duration: 3000
            });
            const error = this.errorService.format(err);
            return of(authActions.loginFail({ errorMessage: error.message }));
          })
        );
      })
    )
  );
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      withLatestFrom(this.store.pipe(select(fromAuth.getAccountType))), // We don't care if its empty
      map(([action, accountType]) => {
        this.sessionStorage.clear();
        if (accountType === 'AzureAD') {
          // Azure AD
          this.msalService.logout();
        } else {
          this.sessionService.logout();
        } // Ebo Account or Unknown
        return authActions.reset(); // Clean Store
      })
    )
  );
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotPassword),
      mergeMap(action => {
        const obs = this.sessionService.forgotPassword(action.payload.email);
        return obs.pipe(
          map((res: boolean) => {
            if (res) {
              this.snackbar.open(`Please check your email for password reset.`, 'Close', {
                duration: 3000
              });
              return authActions.forgotPasswordSuccess();
            } else {
              this.snackbar.open(`Couldn't send the reset password link to your email address`, 'Close', {
                duration: 3000
              });
              return authActions.forgotPasswordFail();
            }
          }),
          catchError(err => {
            const error = this.errorService.format(err);
            this.snackbar.open(`Couldn't send the reset password link to your email address`, 'Close', {
              duration: 3000
            });
            return of(authActions.noop());
          })
        );
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      mergeMap(action => {
        const request = new ResetPasswordRequest();
        request.email = action.payload.email;
        request.password = action.payload.password;
        request.confirmPassword = action.payload.confirmPassword;
        request.code = action.payload.code;
        const obs = this.sessionService.resetPassword(request);
        return obs.pipe(
          map((res: ResetModel) => {
            if (res.isSuccessfull) {
              this.snackbar.open(`Password has been changed`, 'Close', {
                duration: 5000
              });
              this.sessionStorage.clear();
              this.router.navigateByUrl('/sessions/signin');
              return resetPasswordSuccess();
            } else {
              this.snackbar.open(res?.errors?.join(' '), 'Close', {
                duration: 4000
              });
              return authActions.resetPasswordFail();
            }
          }),
          catchError(err => {
            this.snackbar.open(err?.errors?.join(' ') || err?.message || err, 'Close', {
              duration: 4000
            });
            return of(authActions.noop());
          })
        );
      })
    )
  );
  setBotId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setBotId),
        withLatestFrom(this.store.pipe(select(fromAuth.getEnvironment))),
        tap(([action, env]) => {
          const appSettings: UserAppSettings = new UserAppSettings();
          appSettings.environment = env;
          appSettings.botId = action.payload.botId;
          this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
          this.router.navigateByUrl(`/redirect?from=&to=`, { skipLocationChange: true });
        })
      ),
    { dispatch: false }
  );
  setEnvironment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setEnvironment),
        withLatestFrom(this.store.pipe(select(fromAuth.getBotId))),
        tap(([action, botId]) => {
          const appSettings: UserAppSettings = new UserAppSettings();
          appSettings.environment = action.payload.environment;
          appSettings.botId = botId;
          this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
          const fromEnvironment = BotEnvironments.test == action.payload.environment ? BotEnvironments.prod : BotEnvironments.test;
          this.router.navigateByUrl(`/redirect?from=${fromEnvironment.toLowerCase()}&to=${action.payload.environment.toLowerCase()}`, {
            skipLocationChange: true
          });
        })
      ),
    { dispatch: false }
  );

  setBotActive$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setBotActive),
        withLatestFrom(this.store.pipe(select(fromAuth.getBotId))),
        tap(([action, botId]) => {
          const appSettings: UserAppSettings = new UserAppSettings();
          appSettings.botId = botId;
          this.sessionStorage.set(this.userAppSettingsKey, JSON.stringify(appSettings));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.AppState>,
    private errorService: ErrorService,
    private snackbar: MatSnackBar,
    private sessionService: SessionsService,
    private msalService: MsalService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private routeService: RouteService
  ) {}
}
