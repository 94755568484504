import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
  private userPermissions: string[] = [];
  public permissions$ = new BehaviorSubject<void>(null);

  getPermissionsFromSession(): void {
    const userObject = JSON.parse(sessionStorage.getItem('userObject'));
    if (userObject?.permissions) {
      this.userPermissions = userObject.permissions;
    }
  }

  setPermissions(permissions: string[]): void {
    this.userPermissions = permissions;
    this.permissions$.next();
  }

  hasPermission(permission: string): boolean {
    this.getPermissionsFromSession();
    return this.userPermissions.includes(permission);
  }

  hasAnyPermission(permissions: string[]): boolean {
    return permissions.some(permission => this.userPermissions.includes(permission));
  }
}
