import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private router: Router) {}

  storeCurrentUrl(url: string): void {
    // store the current URL for later use
    sessionStorage.setItem('redirectUrl', url);
  }

  redirectToLogin(): void {
    // redirect the user to the login page
    this.router.navigate(['/sessions/signin']);
  }

  redirectToDashboard(environment: string): void {
    // redirect the user to the dashboard
    this.router.navigate([environment + '/reports/view']);
  }

  redirectToSavedRoute(): void {
    // redirect the user to the stored URL
    if (sessionStorage.getItem('redirectUrl')) {
      this.router.navigateByUrl(sessionStorage.getItem('redirectUrl')).then();
    } else {
      setTimeout(() => {
        if (JSON.parse(sessionStorage.getItem('appSettings'))?.urlLoaded?.toLowerCase() === '/') {
          this.redirectToDashboard('test');
        }
      }, 200);
    }
  }
}
